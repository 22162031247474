import AsyncStorage from '@react-native-async-storage/async-storage';
import { IIndexData } from '@rocketcodeit/dibook-reader';
import { Platform } from 'react-native';
import { AddDevice } from '../Models/addDevice';
import { BookList } from '../Models/book-list';
import { Constants } from '../Models/constants';
import { DeviceManager } from '../Models/device-manager';
import { RemoteData } from '../Models/remote-data';
import { UnlookBook } from '../Models/unlookBook';
import apiClient from './api-client';

export class BookService {

  public async syncBooks(): Promise<void> {

    const userId = await AsyncStorage.getItem(Constants.USER_ID) ?? '';
    return apiClient.get<RemoteData>(`/management/books/${userId}`).then(async res => {
      const remoteList = res.data;

      const bookList = new BookList();
      await bookList.setRemoteData(remoteList);

      const serialized = bookList.getSerializedRemoteData();

      await AsyncStorage.setItem(Constants.DATA_BOOKS_LIST, serialized);

    }).catch(err => { console.log(err) })
      .finally(async () => {
        this.downloadCoverImages();
      })
  }

  private async downloadCoverImages() {
    const books = await this.getBooks();

    if (Platform.OS != 'web') {
      const RNFSR = require('./rnfs-factory');
      const RNFS = RNFSR.RNFSFactory.getRNFS();

      const rooPathToSaveData = RNFSR.RNFSFactory.getDataPath();

      const fileDirectory = `${rooPathToSaveData}/copertine`;
      if (await RNFS.exists(fileDirectory)) {
        await RNFS.unlink(fileDirectory);
      }
      await RNFS.mkdir(fileDirectory);

      for (let book of books.books) {
        if (!book.identifier)
          continue;

        const internalCoverImagePath = fileDirectory + `/${book.identifier}.jpg`;
        const res = await RNFS.downloadFile({ fromUrl: book.coverImageData, toFile: internalCoverImagePath }).promise;
        if (res.statusCode != 200) {
          throw new Error();
        }
      }

    }
  }

  public async getBooks(): Promise<BookList> {
    const serializedList = await AsyncStorage.getItem(Constants.DATA_BOOKS_LIST);

    if (serializedList) {
      const remoteData: RemoteData = JSON.parse(serializedList);
      const bookList = new BookList();
      remoteData && await bookList.setRemoteData(remoteData);

      return bookList;
    }

    return new BookList();
  }

  public async unlockBook(unlookBook: UnlookBook) {
    return apiClient.post('/management/unlockbook', unlookBook)
  }

  public async checkDevice(userId: string, deviceId: string) {
    return apiClient.get<boolean>(`/management/devices/${userId}/${deviceId}/check`)
  }

  public async getDevicesList(userId: string) {
    return apiClient.get<DeviceManager>(`/management/devices/${userId}`)
  }

  public async addDevice(device: AddDevice) {
    return apiClient.put<string>(`/management/devices`, device)
  }

  public async deleteDevice(userId: string, deviceId: string) {
    return apiClient.delete<any[]>(`/management/devices/${userId}/${deviceId}`)
  }

  public async getBookIndex(isbn: string) {
    return apiClient.get<IIndexData[]>(`/reader/${isbn}/index`)
  }
}
