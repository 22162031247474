import { BookIndex } from '../Models/book-index';
import { OfflineInfo } from '../Models/offline-info';
import apiClient from './api-client';

export class ReaderService {

  public async getBookIndex(isbn: string) {
    return apiClient.get<BookIndex>(`/reader/${isbn}/index`)
  }

  public async getPdfPageUrl(isbn: string, chapterId: string, page: string) {
    return apiClient.get<string>(`/reader/${isbn}/${chapterId}/pdf-secure/${page}`)
  }

  public async getFluidText(isbn: string, chapterId: string) {
    return apiClient.get<string>(`/reader/${isbn}/${chapterId}/fluid`)
  }

  public async getOfflineData(isbn: string) {
    return apiClient.get<OfflineInfo>(`/reader/${isbn}/offline-info`)
  }

  public async getPageAnnotation(isbn: string, page: string, format: 'PDF' | 'EPUB') {
    return apiClient.get<string>(`/annotation/${isbn}/${page}/${format}`)
  }

  public async savePageAnnotation(isbn: string, page: string, annotation: string, bookFormat: 'PDF' | 'EPUB') {
    return apiClient.post<string>(`/annotation/${isbn}/${page}`, { annotation, bookFormat })
  }

  public async getEpubUrlForOffline(isbn: string) {
    return apiClient.get<string>(`/reader/${isbn}/epub-secure`)
  }

  public async getEpubUrlForOnline(isbn: string) {
    return apiClient.get<string>(`/reader/${isbn}/epub-secure/online`)
  }
}
