export const Constants = {
  DATA_IS_LOGGED_IN: 'isLoggedIn',
  DATA_BOOKS_LIST: 'books',
  API_TOKEN: 'jwtToken',
  USER_ID: 'userId',
  DEVICE_ID: 'deviceId',
  USER_NAME_SURNAME: 'userNameSurname',
  REM_USERNAME: 'remUserName',
  REM_PASSWORD: 'remPassword',
} as const;
