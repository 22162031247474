import { Link, useIsFocused } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {Image, Platform, StyleSheet, Touchable, TouchableHighlight, View, VirtualizedList} from 'react-native';
import Grid from '../../Image/Icons/Grid.svg';
import { Book } from '../../Models/book';
import { BookCategory } from '../../Models/book-category';
import { AppContext } from '../../Providers/AppContext';
import DbText from '../db-text';
import BookListItem from './bookListItem';

export interface IBookListRow {
    category?: BookCategory;
    books?: Book[];
    navigation: any;
    horizontalAlign?: boolean;
    horizontalScroll?: boolean;
    enableScroll?: boolean;
}
function BookListRow(props: IBookListRow) {
    const [books, setBooks] = useState<Book[]>([]);
    const appContext = React.useContext(AppContext);
    const isFocused = useIsFocused();

    useEffect(() => {
        props.category && loadBooks();
    }, []);

    useEffect(() => {
        if (!props.category) {
            setBooks(props.books ?? []);
        }
    }, [props.books]);

    useEffect(() => {
        if (props.category && isFocused && !appContext.isConnected) {
            const category = appContext.booksData.categories.find(x => x.id == props.category!.id);
            if (category?.offline) {
                loadBooks();
            }
        }
    }, [isFocused]);

    const loadBooks = () => {
        const books = appContext.booksData.getBooksFromCategoryId(props.category!.id, 999);
        setBooks(books);
    }

    const getItemCount = (_data: unknown) => books.length;

    return (
        <View style={{ marginBottom: 30 }}>
            {(props.category && (props.horizontalScroll || (Platform.OS != 'android' && Platform.OS != 'ios'))) &&
                (Platform.OS == 'web' ?
                    <Link style={styles.header} to={`/categoryBook/${props.category.id}`}>
                        <DbText style={styles.headerTextStyle}>{props.category.name}</DbText>
                        {props.horizontalScroll &&
                            <Image source={require('../../Image/Png/Icons/Grid.png')} style={{ width: 35, height: 35 }} />
                        }
                    </Link> :
                    <View style={styles.header}>
                        <DbText style={styles.headerTextStyle}>{props.category.name}</DbText>
                        <TouchableHighlight
                            activeOpacity={0.6}
                            underlayColor="transparent"
                            onPress={() => props.navigation.navigate('CategoryBook', { 'categoryId': props.category!.id })}>
                            <Grid width={35} height={35} />
                        </TouchableHighlight>
                    </View>
                )
            }
            <VirtualizedList
                data={books}
                scrollEnabled={props.enableScroll}
                horizontal={props.enableScroll === false ? false : props.horizontalScroll}
                keyExtractor={(item, i) => i.toString()}
                initialNumToRender={1}
                windowSize={4}
                maxToRenderPerBatch={4}
                renderItem={(item) =>
                    <View style={styles.content}>
                        {
                            (item.item as unknown as Book[])?.map((b, i) => {
                                return <BookListItem key={i} book={b} navigation={props.navigation} horizontalScroll={props.horizontalScroll} horizontalAlign={props.horizontalAlign} />;
                            })
                        }
                    </View>
                }
                getItemCount={getItemCount}
                getItem={(_data: Book[], index: number) => {
                    let items: Book[] = []
                    for (let i = 0; i < (Platform.OS != 'android' && Platform.OS != 'ios' ? _data.length : 2); i++) {
                        const bindex = index * (Platform.OS != 'android' && Platform.OS != 'ios' ? _data.length : 2) + i;
                        if (bindex < books.length) {
                            const item = _data[bindex];
                            item && items.push(item);
                        }
                    }
                    return items;
                }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    headerTextStyle: {
        marginTop: 4,
        fontSize: 20,
        fontWeight: 'bold',
        color: '#cc0000',
        textTransform: 'uppercase'
    },
    content: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
});

export default BookListRow;
