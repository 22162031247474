import { useCallback, useContext } from "react";
import { Platform, Text, TextProps, TextStyle } from "react-native";
import { AppContext } from "../Providers/AppContext";

export interface DbTextProps extends TextProps {
    style?: TextStyle;
}
function DbText(props: DbTextProps) {
    const appContext = useContext(AppContext);
    const getTextColor = useCallback(() => {
        if (props.style?.color)
            return props.style?.color;

        return appContext.appereance == 'light' || Platform.OS == 'web' ? 'black' : 'white';
    }, [appContext.appereance, props.style])

    return (<Text {...props} style={{ color: getTextColor(), ...props.style }}>{props.children}</Text>);
}

export default DbText;