import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { Constants } from '../Models/constants';
import {AuthService} from "./auth.service";

const apiClient = axios.create({
    //baseURL: 'http://localhost:5000/api',
    baseURL: 'https://api.dibooklaterza.it/api',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
});

apiClient.interceptors.request.use(
    async config => {
        const token = await AsyncStorage.getItem(Constants.API_TOKEN);
        if (token) {
            config.headers.Authorization = "Bearer " + token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
);

export default apiClient;
