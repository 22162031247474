import { Link, useIsFocused } from "@react-navigation/native";
import React, { useContext, useEffect, useState } from "react";
import { Image, Pressable, TouchableHighlight, View } from "react-native";
import { BaseScreenProps } from "./BaseScreen";
import { AppContext } from "../Providers/AppContext";
import DbModal from "../Components/modals/db-modal";
import DbTooltip from "../Components/db-tooltip";

function BaseScreen(props: BaseScreenProps) {
    const appContext = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    const [deviceMessage, setDeviceMessage] = useState('');
    const isFocused = useIsFocused();

    const [txtTooltip, setTxtTooltip] = useState<string>();
    const [marginTooltip, setMarginTooltip] = useState<number>();
    const [openTooltip, setOpenTooltip] = useState(false);

    useEffect(() => {
        if (props.navigation && isFocused && !isVisible && !appContext.isValidDevice) {
            setIsVisible(!appContext.isValidDevice);
            if (appContext.isAvailableDevices) {
                setDeviceMessage("Il dispositivo non è presente nella lista dispositivi. Aggiungilo alla lista dei dispositivi.")
            } else {
                setDeviceMessage("Il dispositivo non è presente nella lista dispositivi. Per continuare la navigazione scollega uno dei dispositivi in uso.")
            }
        }
    }, [isFocused, appContext.isValidDevice, appContext.isValidDevice]);

    return (
        <>
            <View style={{ backgroundColor: '#cc0000', flexDirection: 'row', alignItems: 'center', padding: 16, height: 60 }}>
                <View style={{ flex: 1 }}>
                    <Link to={'/'}>
                        <Image source={require('../Image/Png/LogoDark.png')} style={{ width: 32, height: 32, resizeMode: 'contain' }} />
                    </Link>
                </View>
                {!props.isLogin && <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', gap: 20 }}>
                    <Pressable onHoverIn={(event) => { setTxtTooltip('Home'); setOpenTooltip(true); setMarginTooltip(258); }} onHoverOut={() => setOpenTooltip(false)}>
                        <Link to={'/'}>
                            <Image source={require('../Image/Png/Icons/Home.png')} style={{ width: 28, height: 28 }} />
                        </Link>
                    </Pressable>
                    {props.onSort && <Pressable onHoverIn={(event) => { setTxtTooltip('Ordinamento'); setOpenTooltip(true); setMarginTooltip(190); }} onHoverOut={() => setOpenTooltip(false)}>
                        <TouchableHighlight onPress={props.onSort}>
                            <Image source={require('../Image/Png/Icons/Sort.png')} style={{ width: 32, height: 32 }} />
                        </TouchableHighlight>
                    </Pressable>}
                    {props.onRefresh && <Pressable onHoverIn={(event) => { setTxtTooltip('Aggiorna lista libri'); setOpenTooltip(true); setMarginTooltip(120); }} onHoverOut={() => setOpenTooltip(false)}>
                        <TouchableHighlight onPress={props.onRefresh}>
                            <Image source={require('../Image/Png/Icons/Reload.png')} style={{ width: 32, height: 32 }} />
                        </TouchableHighlight>
                    </Pressable>}
                    <Pressable onHoverIn={(event) => { setTxtTooltip('Cerca libro'); setOpenTooltip(true); setMarginTooltip(88); }} onHoverOut={() => setOpenTooltip(false)}>
                        <Link to={'/searchBook'}>
                            <Image source={require('../Image/Png/Icons/Search.png')} style={{ width: 32, height: 32 }} />
                        </Link>
                    </Pressable>
                    <Pressable onHoverIn={(event) => { setTxtTooltip('Il tuo account'); setOpenTooltip(true); setMarginTooltip(35); }} onHoverOut={() => setOpenTooltip(false)}>
                        <Link to={'/user'}>
                            <Image source={require('../Image/Png/Icons/Profilo.png')} style={{ width: 32, height: 32 }} />
                        </Link>
                    </Pressable>
                    <Pressable onHoverIn={(event) => { setTxtTooltip('Sblocca un libro'); setOpenTooltip(true); setMarginTooltip(5); }} onHoverOut={() => setOpenTooltip(false)}>
                        <Link to={'/unlockBook'}>
                            <Image source={require('../Image/Png/Icons/AddBook.png')} style={{ width: 32, height: 32 }} />
                        </Link>
                    </Pressable>
                </View>}
            </View>
            <View style={{ padding: props.baseHeader ? 0 : 16 }}>
                {props.children}

                <DbModal open={isVisible} headerText={'Attenzione'} message={deviceMessage}
                    onRequestClose={() => setIsVisible(false)} cancelButtonVisible={false}
                    onConfirm={() => { setIsVisible(false); props.navigation.navigate('User') }} confirmButtonText='Gestione dispositivi' />
            </View>

            <DbTooltip isOpen={openTooltip} margin={marginTooltip}>{txtTooltip}</DbTooltip>
        </>
    );
}

export default BaseScreen;
