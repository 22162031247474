import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from "@react-native-community/netinfo";
import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useState } from 'react';
import { Appearance, ColorSchemeName, Platform, useColorScheme } from 'react-native';
import { BookList } from '../Models/book-list';
import { Constants } from '../Models/constants';
import { BookSortType } from '../Models/enum';
import { LoginResult } from '../Models/login-result';
import { AuthService } from '../Services/auth.service';
import { BookService } from '../Services/book.service';
import apiClient from "../Services/api-client";

export interface IAppContext {
  userLoggedIn: boolean;
  login: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<{ success: boolean; message: string }>;
  logout: () => void;
  syncData: () => Promise<BookList>;
  dataInSync: boolean;
  booksData: BookList;
  appereance: ColorSchemeName;
  isValidDevice: boolean;
  isAvailableDevices: boolean;
  checkDevice: () => void;
  isConnected: boolean;
  showBookSort: boolean;
  setShowBookSort: (value: boolean) => void,
  sortData: (sortType: BookSortType) => void,
  currentSort?: BookSortType
  changeBookLocalState: (bookId: string, isLocal: boolean) => void;
  initialCheckFinished: boolean;
}

const defaultState: IAppContext = {
  userLoggedIn: false,
  login: (_username, _password) => {
    return Promise.resolve({ success: true, message: '' });
  },
  logout: () => { },
  syncData: () => Promise.resolve(new BookList()),
  dataInSync: false,
  booksData: new BookList(),
  appereance: 'light',
  isValidDevice: true,
  isAvailableDevices: true,
  checkDevice: () => { },
  isConnected: true,
  showBookSort: false,
  setShowBookSort: (value: boolean) => { },
  sortData: (sortType: BookSortType) => { },
  currentSort: undefined,
  changeBookLocalState: (bookId: string, isLocal: boolean) => {},
  initialCheckFinished: false
};

export const AppContext = createContext<IAppContext>(defaultState);

export interface AppProviderProps {
  children: any;
}

function AppProvider(props: AppProviderProps) {
  const authService = new AuthService();
  const bookService = new BookService();
  const colorScheme = useColorScheme();

  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const [dataInSync, setDataInSync] = useState<boolean>(false);
  const [booksData, setBooksData] = useState<BookList>(new BookList());
  const [appereance, setAppereance] = useState<ColorSchemeName>(colorScheme);
  const [isValidDevice, setIsValidDevice] = useState<boolean>(true);
  const [isAvailableDevices, setIsAvailableDevices] = useState<boolean>(true);
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [showBookSort, setShowBookSort] = useState<boolean>(false);
  const [currentSort, setCurrentSort] = useState<BookSortType>();
  const [initialCheckFinished, setInitialCheckFinished] = useState<boolean>(false);

  Appearance.addChangeListener((c) => {
    setAppereance(c.colorScheme);
  });


  NetInfo.addEventListener(state => {
    const isCnn = !!state.isConnected && !!state.isInternetReachable;
    if (isCnn !== isConnected) {
      setIsConnected(isCnn)
    }
  });


  useEffect(() => {

    apiClient.interceptors.response.use(
        response => response,
        error => {
          if (error.response.status == 401) {
            logout();
            return;
          }

          return Promise.reject(error);
        });

    // Implementare logica per check login ad apertura app
    AsyncStorage.multiGet([Constants.DATA_IS_LOGGED_IN, Constants.API_TOKEN, Constants.USER_ID], (errors, result) => {
      if (result) {
        const isLogged = !!result[0][1];
        setUserLoggedIn(isLogged);
        isLogged && checkDevice();
      }

      syncData()
          .then(() => {
            setInitialCheckFinished(true);
          })
          .catch(() => {})
    });
  }, []);

  const login = async (
    _username: string,
    _password: string,
    remember: boolean
  ): Promise<LoginResult> => {
    return authService
      .login(_username, _password)
      .then((res) => {
        const result = res.data;
        if (result.jwt) {
          AsyncStorage.multiSet([[Constants.API_TOKEN, result.jwt], [Constants.USER_ID, result.laterzaUserId], [Constants.DATA_IS_LOGGED_IN, 'true'], [Constants.USER_NAME_SURNAME, `${result.name ?? ''} ${result.surname ?? ''}`.trim()]], () => {
            setUserLoggedIn(true);
            checkDevice();
          });
          if (remember) {
            AsyncStorage.multiSet([[Constants.REM_USERNAME, _username], [Constants.REM_PASSWORD, _password]]);
          } else {
            AsyncStorage.multiRemove([Constants.REM_USERNAME, _username, Constants.REM_PASSWORD, _password]);
          }
          const decode = jwtDecode(result.jwt) as any;
          const diff = new Date(decode.exp * 1000).getTime() - new Date().getTime();
          setTimeout(() => {
            logout();
          }, diff);
        } else {
          setUserLoggedIn(false);
        }
        return res;
      })
      .catch((err) => {
        console.log(err)
        // gestione errore
        return Promise.resolve({ success: false, message: 'Errore durante l\'accesso' } as any);
      });
  };

  const logout = () => {
    authService
      .logout()
      .then(() => {
        setUserLoggedIn(false);
      })
      .catch(() => {
        // gestione errore
      });
  };

  const syncData = async (): Promise<BookList> => {

    if(dataInSync)
      return booksData;

    setDataInSync(true);
    isConnected && await bookService.syncBooks();
    const books = await bookService.getBooks();

    setBooksData(books);

    setDataInSync(false);
    return books;
  };

  const checkDevice = () => {
    AsyncStorage.multiGet([Constants.USER_ID, Constants.DEVICE_ID]).then(result => {
      const userId = result[0][1];
      const deviceId = result[1][1];
      bookService.getDevicesList(userId!).then(result => {
        const devices = result.data;
        setIsAvailableDevices(!(Platform.OS == 'web' ? devices.token_limite_streaming == devices.token_attivi_streaming : devices.token_limite_app == devices.token_attivi_app));
        setIsValidDevice(Object.keys(devices.dispositivi ?? []).some((v, i) => devices.dispositivi[v].uuid == deviceId));
      })
    })
  }

  const sortData = async (sortType: BookSortType) => {
    setDataInSync(true);

    setCurrentSort(sortType);
    let books = await bookService.getBooks();
    books.sortBooks(sortType);

    setBooksData(books);

    setDataInSync(false);
  };

  const changeBookLocalState = (bookId: string, isLocal: boolean) => {

    const newBookData = booksData;
    newBookData.setBookIsLocal(bookId, isLocal);

    setBooksData(newBookData);
  }

  return (
    <AppContext.Provider
      value={{ initialCheckFinished, changeBookLocalState, userLoggedIn, login, logout, dataInSync, syncData, booksData, appereance, isValidDevice, isAvailableDevices, checkDevice, isConnected, showBookSort, setShowBookSort, sortData, currentSort }}>
      {props.children}
    </AppContext.Provider>
  );
}

export default AppProvider;
