import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { RootStackParamList } from '../App';
import BookListRow from '../Components/book-list/bookListRow';
import { BookCategory } from '../Models/book-category';
import { AppContext } from '../Providers/AppContext';
import BaseScreen from './BaseScreen';
import SortModal from '../Components/modals/sort-modal';

type ViewerProps = NativeStackScreenProps<RootStackParamList, 'CategoryBook'>;

function CategoryBookScreen({ route, navigation }: ViewerProps) {
    const [category, setCategory] = useState<BookCategory>();
    const [modalVisible, setModalVisible] = useState(false);
    const appContext = React.useContext(AppContext);

    useEffect(() => {
        const { categoryId } = route.params!;
        const category = appContext.booksData.categories.find(x => x.id == categoryId);
        navigation.setOptions({ title: category?.name })
        setCategory(category);
    }, []);

    const onSort = () => appContext.setShowBookSort(true)

    useEffect(() => {
        setModalVisible(appContext.showBookSort);
    }, [appContext.showBookSort]);

    return (
        <BaseScreen withScrollView={false} navigation={navigation} onSort={Platform.OS == 'web' ? onSort : undefined}>
            {(category && !appContext.dataInSync) && <BookListRow category={category} navigation={navigation} horizontalScroll={false} />}

            <SortModal open={modalVisible} />
        </BaseScreen>
    )
}

export default CategoryBookScreen;

