import AsyncStorage from '@react-native-async-storage/async-storage';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {AxiosError} from 'axios';
import React, {useEffect, useState} from 'react';
import {Alert, Image, Platform, StyleSheet, TextInput, View} from 'react-native';
import {RootStackParamList} from '../App';
import DbButton from '../Components/db-button';
import DbModal from '../Components/modals/db-modal';
import DbText from '../Components/db-text';
import {Constants} from '../Models/constants';
import {AppContext} from '../Providers/AppContext';
import {BookService} from '../Services/book.service';
import BaseScreen from './BaseScreen';

type UnlockBookProps = NativeStackScreenProps<RootStackParamList, 'UnlockBook'>;

function UnlockBookScreen({navigation}: UnlockBookProps) {
    const [code, setCode] = useState<string>();
    const bookService = new BookService();
    const [isVisible, setIsVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const appContext = React.useContext(AppContext);

    useEffect(() => {
        navigation.setOptions({title: "Sblocca un libro"});
    }, []);

    const unlookBook = async () => {
        if (code) {

            const userId = await AsyncStorage.getItem(Constants.USER_ID) ?? '';

            bookService.unlockBook({userId: userId, bookCode: code})
                .then((res) => {
                    if (res.status == 204) {
                        setSuccessMessage('Il tuo libro è stato sbloccato con successo!');
                        setIsSuccessMessageVisible(true);
                        setCode(undefined);
                        appContext.syncData();
                    }
                })
                .catch((err: AxiosError<string>) => {
                  setErrorMessage(err.response?.data ?? err.message);
                  setIsVisible(true);
                });
        }
    }

    return (
        <BaseScreen navigation={navigation}>
            <View style={{alignItems: 'center'}}>
                <View style={{alignItems: 'center', gap: 15, maxWidth: 780, marginHorizontal: 'auto', width: '100%'}}>
                    <DbText style={{textAlign: 'center'}}>Inserisci il codice di sblocco stampato in verticale sul
                        bollino argentato che trovi in seconda di copertina dei nostri manuali e clicca su
                        SBLOCCA.</DbText>
                    <DbText style={{textAlign: 'center'}}>Troverai il libro digitale e i suoi contenuti digitali nella
                        tua Area personale/I miei libri e sulle app diBooK.</DbText>
                    {appContext.isConnected ?
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                placeholder="Chiave di attivazione"
                                value={code}
                                onChangeText={setCode}
                            />
                            <View style={{flex: 1}}>
                                <DbButton
                                    disabled={!code}
                                    onPress={unlookBook}
                                    title='SBLOCCA'
                                />
                            </View>
                        </View> :
                        <DbText style={{fontSize: 18, textAlign: 'center', color: '#cc0000'}}>
                            Connettiti alla rete per poter usare questa funzione
                        </DbText>
                    }
                    <Image
                        style={styles.image}
                        resizeMode={'contain'}
                        source={require('../Image/sblocco-libri.png')}/>
                    <DbText style={{textAlign: 'center'}}>Per i libri sprovvisti di codice stampato in verticale sul
                        bollino SIAE del tuo manuale, scrivi a webmaster@laterza.it, indicando la mail con cui sei
                        iscritto al sito Libro + Internet e l’ISBN del tuo libro (il codice numerico che trovi nella
                        quarta di copertina sopra il codice a barre).</DbText>
                </View>
            </View>

            <DbModal
                open={isSuccessMessageVisible}
                headerText={'Complimenti'}
                message={successMessage}
                onRequestClose={() => setIsSuccessMessageVisible(false)}
                onCancel={() => setIsSuccessMessageVisible(false)}
                onConfirm={() => setIsSuccessMessageVisible(false)}
                confirmButtonText='Chiudi'/>

            <DbModal
                open={isVisible}
                headerText={'Attenzione'}
                message={errorMessage}
                onRequestClose={() => setIsVisible(false)}
                onCancel={() => setIsVisible(false)}
                onConfirm={() => setIsVisible(false)}
                confirmButtonText='Chiudi'/>
        </BaseScreen>
    );
}

const styles = StyleSheet.create({
    inputContainer: {
        width: Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos' ? '25%' : '100%',
        minWidth: Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos' ? 300 : '100%',
        flexDirection: 'row'
    },
    input: {
        flex: 2,
        height: 50,
        borderWidth: 1,
        borderColor: '#DDDDDD',
        borderRadius: 10,
        padding: 10,
        marginRight: 15
    },
    image: {
        width: '100%',
        height: 300
    }
});

export default UnlockBookScreen;
