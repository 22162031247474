import { useEffect, useState } from "react";
import { Alert, Modal, Platform, StyleSheet, Text, TextProps, View } from "react-native";
import { AlertButton } from "react-native-windows/types";
import DbButton from "../db-button";

export interface DbModalProps extends TextProps {
    open: boolean;
    headerText: string;
    message: string;
    confirmButtonText?: string;
    cancelButtonVisible?: boolean;
    cancelButtonText?: string;
    onRequestClose: () => void;
    onCancel?: () => void;
    onConfirm: () => void;
}
function DbModal(props: DbModalProps) {
    const [isVisible, setIsVisible] = useState(false);

    const getAlertButtons = () => {
        let buttons: AlertButton[] = [{
            text: props.confirmButtonText ?? "Si",
            onPress: props.onConfirm
        }];

        if (props.cancelButtonVisible) {
            buttons.splice(0, 0, {
                text: props.cancelButtonText ?? "Annulla",
                style: 'cancel',
                onPress: props.onCancel
            })
        }

        return buttons;
    }

    useEffect(() => {
        setIsVisible(props.open);
        if (Platform.OS != 'web' && props.open) {
            Alert.alert(props.headerText, props.message, getAlertButtons());
            setIsVisible(false);
        }
    }, [props.open])

    return (<>{
        (Platform.OS == 'web' && isVisible) &&
        <Modal
            onRequestClose={props.onRequestClose}
            transparent
            visible={isVisible}>
            <View style={[modalStyles.backdrop]}>
                <View style={[modalStyles.containeralt]}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', padding: 15, borderBottomWidth: 1, borderBottomColor: '#ddd', }}>
                        <Text>{props.headerText}</Text>
                    </View>
                    <View style={{ padding: 15 }}>
                        <Text>{props.message}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', padding: 15, borderTopWidth: 1, borderTopColor: '#ddd', width: '100%', position: 'absolute', bottom: 0, justifyContent: 'flex-end', gap: 10 }}>
                        {props.cancelButtonVisible && <DbButton outline={true} style={{ paddingHorizontal: 30 }} title={props.cancelButtonText ?? "Annulla"} onPress={props.onCancel} />}
                        <DbButton style={{ paddingHorizontal: 30 }} title={props.confirmButtonText ?? "Si"} onPress={props.onConfirm} />
                    </View>
                </View>
            </View>
        </Modal>
    }</>);
}

const modalStyles = StyleSheet.create({
    backdrop: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(52, 52, 52, 0.8)',
        justifyContent: 'center',
        alignItems: 'center'
    },
    containeralt: {
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        margin: 'auto',
        maxWidth: '90%',
        width: 500,
        minHeight: 200,
    },
    gap: {
        height: 10
    }
});

export default DbModal;