import {Platform} from "react-native";

export class RNFSFactory {

    public static getRNFS() {
        return;
    }

    public static getDataPath() {
        return;
    }
}
