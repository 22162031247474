import { useContext } from "react";
import { Image, Platform, StyleProp, TextProps, ViewStyle } from "react-native";
import LogoDark from '../Image/LogoDark.svg';
import LogoLight from '../Image/LogoLight.svg';
import { AppContext } from "../Providers/AppContext";

export interface DbLogoProps extends TextProps {
    width: number;
    height: number;
    style?: StyleProp<ViewStyle>;
}
function DbLogo(props: DbLogoProps) {
    const appContext = useContext(AppContext);
    return (
        <>
            {Platform.OS == 'web' ?
                <Image source={require('../Image/Png/LogoLight.png')} style={{ width: props.width, height: props?.height, resizeMode: 'contain' }} /> :
                appContext.appereance == 'light' ?
                    <LogoLight width={props.width} height={props?.height} style={props?.style} /> :
                    <LogoDark width={props.width} height={props?.height} />
            }
        </>
    );
}

export default DbLogo;
