import { useCallback } from "react";
import { StyleSheet, Text, TouchableHighlight, TouchableHighlightProps, View, ViewStyle } from "react-native";

export interface DbButtonProps extends TouchableHighlightProps {
    title?: string;
    style?: ViewStyle;
    outline?: boolean;
    tab?: boolean;
    fontSize?: number;
}
function DbButton(props: DbButtonProps) {

    const defaultColor = '#cc0000';

    const getStyles = useCallback(() => {
        if (props.outline && !props.tab)
            return { ...styles.container, ...styles.outline, ...props.style };

        if (props.outline && props.tab)
            return { ...styles.container, ...styles.tab, ...props.style };

        return { ...styles.container, ...props.style };
    }, [props.style, props.disabled, props.outline]);

    const getBackgroundColor = useCallback(() => {
        let backgroundColor = defaultColor;
        if (props.disabled)
            backgroundColor = `${defaultColor}90`;

        if (props.outline || props.tab)
            backgroundColor = 'transparent';


        return backgroundColor;
    }, [props.style, props.disabled, props.outline, props.tab]);

    const getBorderColor = useCallback(() => {
        let borderColor = defaultColor;
        if (!props.outline)
            borderColor = `transparent`;

        if (props.disabled)
            borderColor = `${defaultColor}90`;

        return borderColor;
    }, [props.style, props.disabled, props.outline]);

    const getTextColor = useCallback(() => {
        let color = props.style?.backgroundColor?.toString() ?? '#ffffff';
        if (props.outline || props.tab)
            color = defaultColor;

        return props.disabled ? `${color}90` : color;

    }, [props.style, props.disabled, props.outline, props.tab]);

    return (
        <TouchableHighlight {...props} style={{ ...getStyles(), backgroundColor: getBackgroundColor(), borderColor: getBorderColor() }}>
            {props.title ?
                <View style={styles.button}>
                    <Text style={{ color: getTextColor(), fontSize: props.fontSize ?? 16, fontWeight: 'bold' }}>{props.title}</Text>
                </View> : props.children}
        </TouchableHighlight>
    );
}
const styles = StyleSheet.create({
    container: {
        height: 50,
        justifyContent: 'center',
        borderRadius: 5
    },
    outline: {
        borderWidth: 2
    },
    tab: {
        borderBottomWidth: 2
    },
    button: {
        alignItems: 'center'
    }
});


export default DbButton;
