/**
 * @format
 */

import {AppRegistry} from 'react-native';
import App from './App';
import file from './app.json';

AppRegistry.registerComponent(file.name, () => App);

AppRegistry.runApplication(file.name, {
  rootTag: document.getElementById('root'),
});
