import React from 'react';
import { Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Book } from '../../Models/book';
import { AppContext } from '../../Providers/AppContext';
import DbText from '../db-text';

export interface IBookListItem {
    book: Book;
    navigation: any;
    horizontalScroll?: boolean;
    horizontalAlign?: boolean;
}
function BookListItem(props: IBookListItem) {
    const appContext = React.useContext(AppContext);
    const largeLayout = Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos';

    const styles = StyleSheet.create({
        container: {
            margin: largeLayout && !props.horizontalAlign ? 10 : 0,
            marginVertical: props.horizontalAlign ? 10 : 0,
            marginBottom: 10,
            marginRight: props.horizontalScroll ? largeLayout ? 30 : 20 : largeLayout ? 40 : 0,
            paddingHorizontal: props.horizontalScroll ? 0 : 10,
            width: props.horizontalScroll ? 120 : props.horizontalAlign ? '100%' : largeLayout ? 230 : '50%',
            borderBottomColor: '#ddd',
            borderBottomWidth: props.horizontalAlign ? 2 : 0
        },
        containerImage: {
            backgroundColor: 'white',
            shadowColor: "#000",
            shadowOffset: {
                width: -2,
                height: 6,
            },
            shadowOpacity: 0.37,
            shadowRadius: 7.49,

            elevation: 12,
        },
        coverImage: {
            width: '100%',
            height: props.horizontalScroll ? 190 : largeLayout ? 320 : 270,
        },
        coverImageHorizontal: {
            width: 100,
            height: 150,
        },
        title: {
            marginTop: 10,
            maxWidth: props.horizontalAlign ? '100%' : 150,
            fontSize: 16,
            fontWeight: 'bold'
        },
        author: {
            maxWidth: props.horizontalAlign ? '100%' : 150,
            fontSize: 13
        }
    });

    const onPress = () => {
        props.navigation.navigate('BookDetail', { 'bookId': props.book.identifier });
    }

    return (
        <TouchableOpacity style={styles.container} onPress={onPress} disabled={!appContext.isValidDevice}>
            {props.horizontalAlign ?
                <View style={{ flexDirection: 'row', marginBottom: 10, gap: 10 }}>
                    <View style={{ flex: 1 }}>
                        <DbText style={styles.title}>{props.book.title}</DbText>
                        <DbText style={styles.author}>{props.book.author}</DbText>
                    </View>
                    {props.book.coverImageData &&
                        <View style={{ width: 100 }}>
                            <Image
                                resizeMode={'stretch'}
                                style={styles.coverImageHorizontal}
                                source={{
                                    uri: props.book.coverImageData
                                }} />
                        </View>}
                </View> :
                <View>
                    {props.book.coverImageData &&
                        <View style={styles.containerImage}>
                            <Image
                                resizeMode={'stretch'}
                                style={styles.coverImage}
                                source={{
                                    uri: props.book.coverImageData
                                }} />
                        </View>}
                    <DbText numberOfLines={1} style={styles.title}>{props.book.title}</DbText>
                    <DbText numberOfLines={1} style={styles.author}>{props.book.author}</DbText>
                </View>
            }
        </TouchableOpacity >
    );
};

export default BookListItem;