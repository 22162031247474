import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, TextInput, View } from 'react-native';
import { RootStackParamList } from '../App';
import BookListRow from '../Components/book-list/bookListRow';
import { Book } from '../Models/book';
import { BookService } from '../Services/book.service';
import BaseScreen from './BaseScreen';

type SearchProps = NativeStackScreenProps<RootStackParamList, 'Search'>;
function SearchScreen({ navigation }: SearchProps) {
  const [searchText, setSearchText] = useState<string>();
  const [allBooks, setAllBooks] = useState<Book[]>([]);
  const [books, setBooks] = useState<Book[]>([]);
  const bookService = new BookService();

  useEffect(() => {
    navigation.setOptions({ title: "Cerca un libro" });
    bookService.getBooks().then(res => {
      setAllBooks(res.books);
    })
  }, []);

  useEffect(() => {
    if (searchText) {
      setBooks(allBooks.filter(x => x.identifier?.toLowerCase().includes(searchText.toLowerCase()) || x.title?.toLowerCase().includes(searchText.toLowerCase())));
    } else {
      setBooks([]);
    }
  }, [searchText]);

  return (
    <BaseScreen navigation={navigation}>
      <View style={{ alignItems: 'center', gap: 15 }}>
        <View style={styles.inputContainer}>
          <TextInput
            autoFocus={true}
            style={styles.input}
            placeholder="Cerca..."
            onChangeText={setSearchText}
          />
        </View>
        {searchText &&
          <View style={styles.resultContainer}>
            {books.length > 0 &&
                <BookListRow navigation={navigation} horizontalAlign={true} books={books} />
            }
          </View>
        }
      </View>
    </BaseScreen>
  );
}

const styles = StyleSheet.create({
  inputContainer: {
    width: Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos' ? '25%' : '100%',
    minWidth: Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos' ? 300 : '100%',
    flexDirection: 'row'
  },
  resultContainer: {
    width: Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos' ? '50%' : '100%',
    minWidth: Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos' ? 500 : '100%',
    padding: 10,
    //shadowColor: '#00000029',
    //shadowRadius: 10
  },
  input: {
    flex: 2,
    height: 50,
    borderWidth: 1,
    borderColor: '#DDDDDD',
    borderRadius: 10,
    padding: 10,
    marginRight: 15
  },
  image: {
    width: '100%',
    height: 300
  }
});

export default SearchScreen;
