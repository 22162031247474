import { useContext, useEffect, useState } from "react";
import { Modal, Pressable, StyleSheet, Text, TextProps, View } from "react-native";
import { BookSortType } from "../../Models/enum";
import { AppContext } from "../../Providers/AppContext";

export interface SortModalProps extends TextProps {
    open: boolean;
}
function SortModal(props: SortModalProps) {
    const [modalVisible, setModalVisible] = useState(false);
    const appContext = useContext(AppContext);

    useEffect(() => {
        setModalVisible(props.open);
    }, [props.open])

    const sortBooks = (sortType: BookSortType) => {
        appContext.setShowBookSort(false);
        appContext.sortData(sortType);
    }

    return (<>{
        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => appContext.setShowBookSort(false)}>
            <View style={{ ...styles.centeredView, ...styles.modalSortBackdrop }}>
                <View style={styles.modalView}>
                    <View style={{ justifyContent: 'flex-start' }}>
                        <Text style={{ ...styles.modalText, fontWeight: 'bold' }}>ORDINAMENTO</Text>
                    </View>
                    <View style={{ alignItems: 'center', marginVertical: 15 }}>
                        <Pressable onPress={() => sortBooks(BookSortType.TITLE)}>
                            <Text style={{ ...styles.modalText, ...styles.modalSortItem }}>TITOLO</Text>
                        </Pressable>
                        <Pressable onPress={() => sortBooks(BookSortType.AUTHOR)}>
                            <Text style={{ ...styles.modalText, ...styles.modalSortItem }}>AUTORE</Text>
                        </Pressable>
                        <Pressable onPress={() => sortBooks(BookSortType.MATTER)}>
                            <Text style={{ ...styles.modalText, ...styles.modalSortItem }}>MATERIA</Text>
                        </Pressable>
                        <Pressable onPress={() => sortBooks(BookSortType.UNLOCK_DATE)}>
                            <Text style={{ ...styles.modalText, ...styles.modalSortItem }}>RECENTI</Text>
                        </Pressable>
                    </View>
                    <View style={{ alignItems: 'flex-end' }}>
                        <Pressable onPress={() => appContext.setShowBookSort(false)}>
                            <Text style={{ ...styles.modalText, fontWeight: 'bold' }}>Chiudi</Text>
                        </Pressable>
                    </View>
                </View>
            </View>
        </Modal>
    }</>);
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 10,
        width: '60%',
        padding: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    modalText: {
        color: '#000'
    },
    modalSortItem: {
        padding: 5
    },
    modalSortBackdrop: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(52, 52, 52, 0.8)',
        justifyContent: 'center',
        alignItems: 'center'
    },
});

export default SortModal;