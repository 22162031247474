import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import {Image, Platform, StyleSheet, TextInput, TouchableOpacity, TouchableWithoutFeedback, View} from 'react-native';
import { RootStackParamList } from '../App';
import DbButton from '../Components/db-button';
import DbText from '../Components/db-text';
import DbModal from '../Components/modals/db-modal';
import Trash from '../Image/Icons/Trash.svg';
import { AddDevice } from '../Models/addDevice';
import { Constants } from '../Models/constants';
import { DeviceDetail } from '../Models/device-manager';
import { AppContext } from '../Providers/AppContext';
import { BookService } from '../Services/book.service';
import BaseScreen from './BaseScreen';

type UserProps = NativeStackScreenProps<RootStackParamList, 'User'>;
function UserScreen({ navigation }: UserProps) {
    const appContext = React.useContext(AppContext);
    const bookService = new BookService();
    const [devices, setDevices] = useState<DeviceDetail[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [currentDevice, setCurrentDevice] = useState<DeviceDetail>();
    const [canAddDevice, setCanAddDevice] = useState(false);
    const [deviceName, setDeviceName] = useState<string>('');
    const [displayName, setDisplayName] = useState<string>('');

    const [deviceLimit, setDeviceLimit] = useState(0);

    const [loadingDevices, setLoadingDevices] = useState(true);

    useEffect(() => {
        navigation.setOptions({ title: '' });
        setUserDisplayName();
    }, []);

    useEffect(() => {
        if(appContext.isConnected) {
            getDevicesList();
        }
    }, [appContext.isConnected]);

    const setUserDisplayName = async () => {
        const userDisplayName = await AsyncStorage.getItem(Constants.USER_NAME_SURNAME) ?? '';
        setDisplayName(userDisplayName);
    }

    const getDevicesList = async () => {

        setLoadingDevices(true);

        const userId = await AsyncStorage.getItem(Constants.USER_ID) ?? '';

        bookService.getDevicesList(userId).then(res => {
            const data = res.data;
            let devices: DeviceDetail[] = [];
            if (data.dispositivi) {
                Object.keys(data.dispositivi).forEach((v, i) => devices.push(data.dispositivi[v]));
            }
            if (Platform.OS == 'web' && data.token_limite_streaming == data.token_attivi_streaming) {
                setDeviceLimit(data.token_limite_streaming);
            } else if (Platform.OS != 'web' && data.token_limite_app == data.token_attivi_app) {
                setDeviceLimit(data.token_limite_app);
            } else {
                setDeviceLimit(0);
            }

            AsyncStorage.getItem(Constants.DEVICE_ID).then(id => {
                const currentDevice = devices.find(x => x.uuid == id);
                if (currentDevice)
                    currentDevice.nome_dispositivo = `${currentDevice.nome_dispositivo} (dispositivo corrente)`;

                setCanAddDevice(!currentDevice);
                setDevices(devices);
            })
        }).catch((err) => {
            console.log(err)
        })
        .finally(() => {
            setLoadingDevices(false)
        })
    }

    const onDeleteItem = (device: DeviceDetail) => {
        setIsVisible(true);
        setCurrentDevice(device);
    }

    const addDevice = async () => {
        const userId = await AsyncStorage.getItem(Constants.USER_ID) ?? '';
        let device: AddDevice = {
            userId: userId,
            deviceName: deviceName,
            deviceTarget: Platform.OS == 'web' ? 'streaming' : 'app',
            deviceType: Platform.OS,
            userAgent: ''
        }
        bookService.addDevice(device).then(res => {
            const data = res.data;
            if (data) {
                setDeviceName('');
                AsyncStorage.setItem(Constants.DEVICE_ID, res.data);
                getDevicesList()
                    .then(() => {
                        appContext.checkDevice();
                    })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteDevice = async () => {
        const userId = await AsyncStorage.getItem(Constants.USER_ID) ?? '';
        bookService.deleteDevice(userId, currentDevice!.uuid).then(res => {
            setIsVisible(false);
            appContext.checkDevice();
            getDevicesList();
        }).catch((err) => {
            console.log(err)
        })
    }

    const DeviceItem = ({ item }: any) => (
        <View style={styles.item}>
            <DbText style={styles.name}>{item.nome_dispositivo}</DbText>
            <TouchableOpacity style={styles.closeBtn} onPress={() => onDeleteItem(item)}>
                {Platform.OS == 'web' ?
                    <Image source={require('../Image/Png/Icons/Trash.png')} style={{ width: 25, height: 25 }} />
                    : <Trash width={25} height={25} />}
            </TouchableOpacity>
        </View>
    );

    return (
        <BaseScreen>
            <View style={{ padding: 16, flex: 1, alignItems: 'center' }}>

                <View style={styles.container}>
                    <View style={{ width: '100%' }}>
                        <DbText>Benvenuto</DbText>
                        <DbText style={{ fontSize: 20, fontWeight: 'bold', color: '#cc0000' }}>{displayName}</DbText>
                    </View>
                    {appContext.isConnected ?
                        <>
                            <DbText style={{ fontSize: 20, marginTop: 40, marginBottom: 20, color: '#cc0000', textAlign: 'center' }}>
                                I miei dispositivi
                            </DbText>

                            {loadingDevices ?
                                <DbText style={{ width: '100%', textAlign: 'center', fontSize: 18 }}>
                                    Caricamento dispositivi in corso...
                                </DbText> :
                                <>
                                    {(devices.length > 0) ?
                                        devices.map((item, i) => <DeviceItem key={i} item={item} />) :
                                        <DbText style={{ width: '100%', textAlign: 'center', fontSize: 18 }}>
                                            Nessun dispositivo inserito
                                        </DbText>
                                    }
                                </>
                            }


                            {canAddDevice &&
                                (deviceLimit == 0 ?
                                        <View style={styles.inputContainer}>
                                            <TextInput
                                                style={styles.input}
                                                value={deviceName}
                                                placeholder="Nome dispositivo corrente"
                                                onChangeText={setDeviceName}
                                            />
                                            <View style={{ flex: 1.5 }}>
                                                <DbButton style={{ paddingHorizontal: 25 }} title="Aggiungi" onPress={addDevice} disabled={!deviceName} />
                                            </View>
                                        </View> :
                                        <DbText style={{ marginTop: 40, fontSize: 18, textAlign: 'center', color: '#cc0000' }}>
                                            Hai superato i {deviceLimit} accessi su dispositivi/browser consentiti.
                                            Per continuare la navigazione scollega uno dei dispostivi in uso (oppure un'altra sessione)
                                        </DbText>
                                )}
                        </> :
                        <DbText style={{ marginTop: 40, fontSize: 18, textAlign: 'center', color: '#cc0000' }}>
                            Non è possibile gestire i dispositivi in modalità offline. Connettiti alla rete per visualizzare la lista dei tuoi dispositivi
                        </DbText>
                    }
                </View>


                <View style={{ alignItems: 'center', marginVertical: 50 }}>
                    {(Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos') && <DbButton style={{ paddingHorizontal: 30, marginBottom: 30 }} outline title="Torna alla home" onPress={() => navigation.navigate('Home')} />}
                    <DbButton style={{ paddingHorizontal: 30 }} title="Log out" onPress={() => appContext.logout()} />
                </View>
            </View>

            <DbModal open={isVisible} headerText={'Attenzione'} message={'Confermi la cancellazione del dispositivo selezionato?'}
                cancelButtonVisible={true} onRequestClose={() => setIsVisible(false)}
                onCancel={() => setIsVisible(false)} onConfirm={() => deleteDevice()} />
        </BaseScreen>
    );
}

const styles = StyleSheet.create({
    container: {
        width: Platform.OS == 'web' || Platform.OS == 'macos' || Platform.OS == 'windows' ? 400 : '100%'
    },
    item: {
        width: '100%',
        minWidth: '100%',
        padding: 5,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    closeBtn: {
        backgroundColor: '#DDDDDD',
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 5
    },
    name: {
        fontSize: 16
    },
    inputContainer: {
        width: '100%',
        alignItems: 'center',
        marginVertical: 50,
        flexDirection: 'row',
        margin: 10
    },
    input: {
        flex: 2,
        height: 50,
        borderWidth: 1,
        borderColor: '#DDDDDD',
        borderRadius: 10,
        padding: 10,
        marginRight: 15
    },
});

export default UserScreen;
