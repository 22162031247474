import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { RootStackParamList } from '../App';
import BookListRow from '../Components/book-list/bookListRow';
import DbText from '../Components/db-text';
import SortModal from '../Components/modals/sort-modal';
import { AppContext } from '../Providers/AppContext';
import BaseScreen from './BaseScreen';

type HomeProps = NativeStackScreenProps<RootStackParamList, 'Home'>;

const HomeScreen = ({ navigation }: HomeProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  const appContext = React.useContext(AppContext);

  useEffect(() => {
    if(appContext.booksData.books.length === 0)
      onRefresh();
  }, []);

  const onRefresh = React.useCallback(() => {
    appContext.syncData();
  }, [appContext]);

  useEffect(() => {
    setModalVisible(appContext.showBookSort);
  }, [appContext.showBookSort]);

  return (
    <BaseScreen navigation={navigation} onRefresh={onRefresh} onSort={() => appContext.setShowBookSort(true)}>
      {
        !appContext.dataInSync &&
        appContext.booksData.onlineCategory?.map((b, i) => {
          return <BookListRow key={i} category={b} navigation={navigation} horizontalScroll={true} />;
        })
      }
      {appContext.dataInSync &&
          <div style={{textAlign: 'center'}}>
            <ActivityIndicator style={{marginTop: 48}} size={'large'} color={'#cc0000'} />
            <h3 style={{fontFamily: 'roboto'}}>Attendi il caricamento della lista libri...</h3>
          </div>
      }

      <SortModal open={modalVisible} />
    </BaseScreen>
  );
};

export default HomeScreen;
