import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useContext, useEffect, useState } from 'react';
import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TextInput,
  TouchableHighlight,
  View
} from 'react-native';
import { AuthStackParamList } from '../App';
import DbButton from '../Components/db-button';
import DbLogo from '../Components/db-logo';
import DbText from '../Components/db-text';
import { Constants } from '../Models/constants';
import { AppContext } from '../Providers/AppContext';
import BaseScreen from './BaseScreen';

type LoginProps = NativeStackScreenProps<AuthStackParamList, 'Login'>;

function LoginScreen({ navigation }: LoginProps) {
  const appContext = useContext(AppContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const [hidePass, setHidePass] = useState(true);

  useEffect(() => {
    AsyncStorage.multiGet([Constants.REM_USERNAME, Constants.REM_PASSWORD]).then(result => {
      const userName = result[0][1];
      const password = result[1][1];
      setUsername(userName ?? '');
      setPassword(password ?? '');
      setToggleCheckBox(!!userName)
    });
  }, []);

  const onPressLoginButton = () => {
    appContext.login(username, password, toggleCheckBox).then(result => {
      if (!result.success) {
        setLoginError(result.message);
      }
    });
  };

  return (
    <BaseScreen isLogin={true}>
      <View style={{ width: '50%', alignSelf: 'center' }}>
        <View style={{ alignItems: 'center' }}>
          <DbLogo width={110} height={110} style={{ margin: 10 }} />
        </View>
        <DbText style={{ ...styles.subTitle, marginTop: 30, textAlign: 'center', width: '100%' }}>Utilizza le stesse credenziali di</DbText>
        <DbText style={{ ...styles.subTitle, color: '#cc0000', fontWeight: 'bold', marginBottom: 20, textAlign: 'center', width: '100%' }}
          onPress={() => Linking.openURL('https://www.laterzalibropiuinternet.it/')}>
          laterzalibropiuinternet.it
        </DbText>
        <View style={{ width: '50%', alignSelf: 'center' }}>
          <TextInput
            style={styles.input}
            onChangeText={setUsername}
            value={username}
            placeholder={'Email'}
          />
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <TextInput
              textContentType={'newPassword'}
              style={{ ...styles.input, flex: 1 }}
              onChangeText={setPassword}
              value={password}
              secureTextEntry={hidePass ? true : false}
              placeholder="Password"
            />
            <TouchableHighlight underlayColor={'transparent'} onPress={() => setHidePass(!hidePass)}>
              {hidePass ?
                <Image source={require('../Image/Png/Icons/Visibility.png')} style={{ width: 32, height: 32, marginTop: 15 }} /> :
                <Image source={require('../Image/Png/Icons/VisibilityOff.png')} style={{ width: 32, height: 32, marginTop: 15 }} />}
            </TouchableHighlight>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 10 }}>
            <input type='checkbox' checked={toggleCheckBox} onChange={(event: any) => setToggleCheckBox(event.target.checked)} />
            <DbText style={{ fontSize: 18 }} onPress={() => setToggleCheckBox(!toggleCheckBox)}>Ricordami</DbText>
          </View>
        </View>

        <View style={{ alignItems: 'center', marginVertical: 20 }}>
          {
            loginError && <Text style={styles.msgError}>
              {loginError ?? ''}
            </Text>
          }
          <DbButton onPress={onPressLoginButton} title="Accedi" style={{ width: 200 }} />
        </View>
        <View style={{ alignItems: 'center' }}>
          <DbText style={{ fontSize: 18, marginBottom: 15 }} onPress={() => Linking.openURL('https://www.laterza.it/ph-reset-password.php')}>Recupera password</DbText>
          <DbText style={{ fontSize: 18 }} onPress={() => Linking.openURL('https://www.laterzalibropiuinternet.it/registrazione.php')}>Non sei iscritto? <DbText style={{ textDecorationLine: 'underline' }}>Clicca qui</DbText></DbText>
        </View>
      </View>
    </BaseScreen>
  );
}

const styles = StyleSheet.create({
  subTitle: {
    width: '70%',
    fontSize: 25
  },
  msgError: {
    fontSize: 20,
    color: 'red',
    marginBottom: 20
  },
  input: {
    height: 40,
    margin: 12,
    borderBottomWidth: 1,
    padding: 10,
    fontSize: 16
  },
});

export default LoginScreen;
