import { useCallback, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

export interface DbTooltipProps {
    children: any,
    isOpen: boolean,
    mouseEvent?: any,
    margin?: number
}
function DbTooltip(props: DbTooltipProps) {
    const [mouseEvent, setMouseEvent] = useState<any>();
    const [isOpen, setIsOpen] = useState(props.isOpen);

    useEffect(() => {
        console.log(props.mouseEvent?.pageX)
        setMouseEvent(props.mouseEvent)
    }, [props.mouseEvent])

    useEffect(() => setIsOpen(props.isOpen), [props.isOpen])

    const getStyles = useCallback(() =>
        StyleSheet.create({
            tooltip: {
                backgroundColor: '#000',
                position: 'absolute',
                top: 65,
                right: props.margin ?? 20,
            },
            text: {
                color: '#fff',
                padding: 10
            }
        }), [props.mouseEvent, props.margin]);

    return (
        <>
            {isOpen &&
                <View style={{ ...getStyles().tooltip }}>
                    <Text style={{ ...getStyles().text }}>
                        {props.children}
                        {/*JSON.stringify(mouseEvent ?? '')*/}
                    </Text>
                </View >}
        </>);
}

export default DbTooltip;