/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 */

import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { IAdditionalContent, IIndexData, OnFluidTextSourceRequestedResult, OnPdfPageSourceRequestedResult } from '@rocketcodeit/dibook-reader';
import React, {useEffect, useRef, useState} from 'react';
import {ActivityIndicator, Button, View} from 'react-native';
import { RootStackParamList } from '../App';
import DiBookReaderWrapper from '../Components/dibook-reader-wrapper/index.web';
import { ReaderService } from '../Services/reader.service';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from "@react-navigation/native";
import DbButton from "../Components/db-button";
import {WebErrorPage} from "../Components/web-error-page.web";

type ViewerProps = NativeStackScreenProps<RootStackParamList, 'Viewer'>;

function ViewerScreen({ route, navigation }: ViewerProps): JSX.Element {

  const [loaded, setLoaded] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [indexData, setIndexData] = useState<IIndexData[]>([]);
  //const [bookId, setBookId] = useState<string>();
  const bookIdRef = useRef<string>();
  const openPdfRef = useRef<boolean>();

  const [annotations, setAnnotations] = useState<string>('');
  const [additionalContents, setAdditionalContents] = useState<IAdditionalContent[]>([]);
  const [epubUrl, setEpubUrl] = useState<string>();
  const readerService = new ReaderService();
  const [errorOnLoading, setErrorOnLoading] = useState<boolean>(false);

  const isFocused = useIsFocused();

  const isSavingAnnotations = useRef<boolean>(false);

  useEffect(() => {

    if(!isFocused) {
      setLoaded(false);
      setErrorOnLoading(false);
      setEpubUrl(undefined);
      setIndexData([]);
      setTotalPages(0);
      return;
    }

    const { bookId, openPdf } = route.params!;
    bookIdRef.current = bookId;

    let openPdfViewer = true;

    // I need to check if openPdf is a boolean. If not, cast to boolean
    if(typeof openPdf !== 'boolean') {
      openPdfViewer = openPdf === 'true';
    } else {
      openPdfViewer = openPdf;
    }

    openPdfRef.current = openPdfViewer;

    initData(bookId, openPdfViewer);
  }, [isFocused]);

  const initData = async (bookId: string, openPdf: boolean) => {

    const data = (await readerService.getBookIndex(bookId)).data;

    setTotalPages(data.totalPages);

    const index = data.chapters.map(c => {
      return {
        bookId: data.bookId,
        chapterId: c.id,
        chapterName: c.name,
        ...c
      } as IIndexData
    })

    if(openPdf && index.length === 0) {
      setErrorOnLoading(true);
      return;
    }

    setIndexData(index);
    setAdditionalContents(data.additionalContents);

    if (!openPdf) {
      try{
        const res = (await readerService.getEpubUrlForOnline(bookId));
        setEpubUrl(res.data);
      }catch(err) {
        setErrorOnLoading(true);
        return;
      }
    }

    setLoaded(true);
  }

  const onCurrentPageChange = async (page: string) => {
    await AsyncStorage.setItem(bookIdRef.current!, page);
  }

  const onAnnotationChange = async (page: string, annotations?: string) => {

    if(isSavingAnnotations.current)
        return;

    isSavingAnnotations.current = true;

    readerService.savePageAnnotation(bookIdRef.current!, page, annotations ?? '', openPdfRef.current ? 'PDF' : 'EPUB')
        .then((res) => {
            console.log("Annotation saved");
        })
        .finally(() => {
            isSavingAnnotations.current = false;
        })
  }

  const onPdfPageSourceRequested = async (page: string) => {
    const chapter = indexData.find(c => c.pageLabels.includes(page))!;
    const pageUrl = (await readerService.getPdfPageUrl(chapter.bookId, chapter.chapterId, page)).data;

    return {
      sourceType: 'URL',
      content: pageUrl,
      password: `AB8374JJ${bookIdRef.current?.padEnd(16,'0')}H48js83A`
    } as OnPdfPageSourceRequestedResult;
  }

  const onFluidTextSourceRequested = async (page: string) => {
    const chapter = indexData.find(c => c.pageLabels.includes(page))!;
    let cfluidText = (await readerService.getFluidText(chapter.bookId, chapter.chapterId)).data;

    return {
      content: cfluidText
    } as OnFluidTextSourceRequestedResult;
  }

  const onGetAnnotationHandler = async (page: string): Promise<string> => {
    const chapter = indexData.find(c => c.pageLabels.includes(page))!;

    if(!chapter)
      return Promise.resolve('');

    return readerService.getPageAnnotation(chapter.bookId, page, openPdfRef.current ? 'PDF' : 'EPUB')
        .then((res) => {
          if(res.data)
            return JSON.stringify(res.data ?? undefined);
          else
            return '';
        })
        .catch((err) => {
          console.log(err);
          return '';
        });
  }

  return (
    <View>
      {(loaded && isFocused) &&
          <DiBookReaderWrapper
              bookId={bookIdRef.current!}
              onBackButtonClick={() => {navigation.navigate('Home');}}
              onCurrentPageChange={onCurrentPageChange}
              totalPages={totalPages}
              index={indexData}
              epubUrl={epubUrl}
              getAnnotations={onGetAnnotationHandler}
              onAnnotationChange={onAnnotationChange}
              additionalContents={additionalContents}
              onPdfPageSourceRequested={onPdfPageSourceRequested}
              onFluidTextSourceRequested={onFluidTextSourceRequested} />}

      <>
        {errorOnLoading &&
            <WebErrorPage
              buttonLabel={'Torna alla Home'}
              navigation={navigation}
              message={'Stai tentado di accedere ad un formato del libro inesistente'}
              returnTo={'Home'}
            />
        }
      </>
    </View>
  );
}

export default ViewerScreen;
