import { useCallback, useContext } from "react";
import { Platform, TextStyle } from "react-native";
import RenderHTML from "react-native-render-html";
import { AppContext } from "../Providers/AppContext";

export interface DbRenderHtmlProps {
    style?: TextStyle;
    html: string;
    contentWidth?: number | undefined;
}
function DbRenderHtml(props: DbRenderHtmlProps) {
    const appContext = useContext(AppContext);
    const getTextColor = useCallback(() => {
        if (props.style?.color)
            return props.style?.color;

        return appContext.appereance == 'light' || Platform.OS == 'web' ? 'black' : 'white';
    }, [appContext.appereance, props.style])

    return (<RenderHTML defaultTextProps={{ style: { color: getTextColor(), ...props.style } }} contentWidth={props.contentWidth} source={{ html: props.html ?? '' }} />);
}

export default DbRenderHtml;